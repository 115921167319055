<template>
  <label class="text-title-3 primary--text text--darken-4"> {{title}}
        <v-text-field
        v-if="$attrs.inputType === 'number'"
        outlined
        v-bind="$attrs"
        v-on="inputListeners"
        large
        color="primary"
        class="ma-0 pa-0"
        type="number"
        >
        </v-text-field>
<!-- v-mask="'###'"  -->
        <v-text-field
        v-else
        outlined
        v-bind="$attrs"
        v-on="inputListeners"
        large
        color="primary"
        class="ma-0 pa-0"
        >
        </v-text-field>

    </label>
</template>

<script>
import { mask } from 'vue-the-mask'

export default {
  directives: { mask },
  props: ['title'],
  computed: {
    inputListeners: function () {
      var vm = this
      // `Object.assign` merges objects together to form a new object
      return Object.assign({},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function (event) {
            vm.$emit('input', event)
          }
        }
      )
    }
  }

}
</script>

<style lang="scss" scoped>

// .v-btn[data-v-6350f88b] {
//     border-radius: 10px !important;
// }
//  .v-btn--outlined {
//     border: 3px solid currentColor !important;
// }
</style>
